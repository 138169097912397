import React, { useState } from 'react';
import Section from 'components/Section';
import Features2 from 'components/Features2';
import Image from 'next/image';
import Link from 'next/link';
import { motion } from 'framer-motion';
import 'components/Threesteps';

const Step = ({ step, instructions, src, mousePos }) => {
    const [hoverItem, setHover] = useState(false);
    let mousePosVal = 'calc(50% + ' + mousePos + ')';
    return (
        <div className="column is-one-fourth">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '32px'
                }}
            >
                <motion.div
                    onHoverStart={(e) => setHover(step)}
                    onHoverEnd={(e) => setHover(false)}
                    animate={{
                        scale: hoverItem === step ? [1, 0.975, 1] : [1, 1, 1],
                        //opacity: hoverItem === step ? [1, 0.8, 1] : [1, 1, 1],
                        filter:
                            hoverItem === step
                                ? [
                                      'brightness(1)',
                                      'brightness(0.95)',
                                      'brightness(1)'
                                  ]
                                : [
                                      'brightness(1)',
                                      'brightness(1)',
                                      'brightness(1)'
                                  ],
                        boxShadow:
                            hoverItem === step
                                ? [
                                      '0 8px 23px -4px rgba(142,149,173,0.50)',
                                      '0 2px 44px -4px rgba(142,149,173,0.50)',
                                      '0 8px 23px -4px rgba(142,149,173,0.50)'
                                  ]
                                : [
                                      '0 8px 23px -4px rgba(142,149,173,0.50)',
                                      '0 8px 23px -4px rgba(142,149,173,0.50)',
                                      '0 8px 23px -4px rgba(142,149,173,0.50)'
                                  ]
                    }}
                    transition={{
                        delay: 0.2,
                        duration: 0.6,
                        times: [0.2, 0.3, 0.5]
                    }}
                    style={{
                        background: '#FFFFFF',
                        boxShadow: '0 8px 23px -4px rgba(142,149,173,0.50)',
                        borderRadius: '16px',
                        height: '190px',
                        width: '266px',
                        minWidth: '266px',
                        position: 'relative',
                        cursor: 'pointer'
                    }}
                >
                    <motion.div
                        animate={{
                            scale: hoverItem === step ? [1, 0.9, 1] : [1, 1, 1]
                        }}
                        transition={{
                            delay: 0.4,
                            duration: 0.6,
                            times: [0.2, 0.3, 0.5]
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '68px',
                            width: '68px',
                            background: '#F4F5F7',
                            backgroundImage:
                                'linear-gradient(180deg, #F4F5F7 0%, #ECEDF2 100%)',
                            boxShadow: '0 6px 14px -2px rgba(142,149,173,0.70)',
                            borderRadius: '14px',
                            position: 'absolute',
                            top: '-25px',
                            left: '-25px',
                            zIndex: '4'
                        }}
                    >
                        <p
                            style={{
                                color: '#04184a',
                                margin: 'auto',
                                fontFamily: 'Rubik, sans-serif',
                                fontSize: '42px',
                                fontWeight: '700'
                            }}
                        >
                            {step}
                        </p>
                    </motion.div>
                    <div
                        style={{
                            margin: 'auto',
                            position: 'absolute',
                            height: '190px',
                            width: '266px',
                            minWidth: '266px',
                            overflow: 'hidden',
                            borderRadius: '16px'
                        }}
                    >
                        <Image
                            src={src}
                            alt={step + ' image'}
                            height={189}
                            width={266}
                            quality={100}
                        />
                    </div>
                    <motion.div
                        animate={{
                            y: hoverItem === step ? [8, 0, 8] : 8,
                            x: hoverItem === step ? [5, 0, 5] : 5,
                            scale: hoverItem === step ? [1, 0.875, 1] : 1
                        }}
                        duration={{ duration: 1, times: [0.2, 0.3, 0.5] }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: mousePosVal,
                            y: 8,
                            x: 5
                        }}
                    >
                        <Image
                            src={'/features/pointing.png'}
                            alt={'Mouse pointer'}
                            height={40}
                            width={40}
                        />
                    </motion.div>
                </motion.div>
                <p
                    style={{
                        fontSize: '24px',
                        fontWeight: '600',
                        color: '#04184a',
                        marginTop: '24px'
                    }}
                >
                    {instructions}
                </p>
            </div>
        </div>
    );
};

function Threesteps() {
    const [hovered, setHovered] = useState(null);

    return (
        <Section size={'small'}>
            <div
                className="container"
                style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
            >
                <div
                    className="columns is-centered"
                    style={{
                        alignItems: 'left',
                        marginBottom: '4rem',
                        paddingTop: '6rem',
                        flexDirection: 'column',
                        display: 'flex'
                    }}
                >
                    <h3 style={{ fontSize: '24px' }}>
                        Don't just take our word for it
                    </h3>
                    <h2
                        className="bold"
                        style={{ fontSize: '44px', fontWeight: '800' }}
                    >
                        Try tabExtend in 3 simple steps
                    </h2>
                </div>
            </div>
            <div className="container">
                <div
                    className="columns is-centered"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginBottom: '2rem',
                        marginTop: '4rem',
                        position: 'relative'
                    }}
                >
                    <div
                        className="is-hidden-touch"
                        style={{
                            height: '6px',
                            borderTop: '8px dashed #E1E2E3',
                            width: '600px',
                            marginLeft: '30px',
                            position: 'absolute',
                            top: '137px'
                        }}
                    />
                    <Step
                        step={'1'}
                        instructions={'Choose browser below'}
                        src={'/features/step1.png'}
                        mousePos={'70px'}
                    />
                    <Step
                        step={'2'}
                        instructions={'Click add to browser'}
                        src={'/features/step2.png'}
                        mousePos={'20px'}
                    />
                    <Step
                        step={'3'}
                        instructions={'Open a new tab'}
                        src={'/features/step3.png'}
                        mousePos={'30px'}
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        color: '#8A95A0',
                        marginTop: '4rem',
                        marginBottom: '2rem'
                    }}
                >
                    <p style={{ fontSize: '18px', fontWeight: '600' }}>
                        Read more about browser permissions and{' '}
                        <span
                            className="linkWrapperGray"
                            style={{ textDecoration: 'underline' }}
                        >
                            <Link
                                href="/guide/getting-started#Permissions"
                                alt="Getting started guide"
                            >
                                why they are needed here
                            </Link>
                        </span>
                        .
                    </p>
                    <p style={{ fontSize: '18px', fontWeight: '600' }}>
                        Any other questions? Send us a message{' '}
                        <span
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={() => Chatra('openChat', true)}
                        >
                            here
                        </span>
                        .
                    </p>
                </div>
            </div>
        </Section>
    );
}

export default Threesteps;
